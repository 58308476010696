import React, { useState } from "react";
import handshake from "../../assets/icons/handshake.svg";
import { KeyboardArrowLeft } from "@material-ui/icons";
import firebase from "../../firebase";
import { Checkbox } from "@material-ui/core";
import { Link } from "react-router-dom";

import { FooterV2 } from "../../components";

const PrivacyPolicy = (props) => {
  const { history, match, user, isUser } = props;
  const { projectName } = match.params;

  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const hideLinks =
    match.url.includes("faq") ||
    match.url.includes("cookie-policy") ||
    match.url.includes("privacy-policy");

  return (
    <>
      <div className="page">
        <button
          className="secondary back"
          onClick={() => history.goBack()}
          disabled={false}
        >
          <KeyboardArrowLeft />
          Back
        </button>
        <div className="privacy-policy">
          <img src={handshake} alt="handshake icon" />
          <h1 className="title">It is your first time here! </h1>
          <p className="sub-title subtitle ">
            <strong>
              Your privacy is important to us. As it is your first time here,
              please read and accept our privacy policy. It explains what we do
              and how your data will be protected.
            </strong>
          </p>
          <div className="body">
            <p>
              Your employer has signed up to our services in order to further
              understand the development needs of its employees. Our service
              requires you to answer a series of questions. An automated report
              is then generated and sent by email to you and your employer. You
              can also access this report directly (for a period of one year) by
              logging into our platform.
              <br />
              <br />
              We only collect the minimum amount of personal information that we
              need to from you in order to provide this service to you and your
              employer.
              <br />
              <br />
              We only use your personal information in ways which you might
              expect – for example to analyse your submissions and to share the
              end report with your employer. This is calculated to have minimum
              privacy impact on you.
              <br />
              <br />
              Any information that you submit that relates to you personally,
              will be deleted after one year. However, we do collect aggregated
              data (that is completely anonymous) from the submissions we
              receive so that we can conduct further research in this area.
              <br />
              <br />
              Our full form privacy statement is accessible here:{" "}
              <Link to={`/${projectName}/privacy-policy-long`}>
                Privacy policy
              </Link>
            </p>

            {user && isUser && (
              <p style={{ display: "flex", paddingTop: "2rem" }}>
                <div>
                  <Checkbox
                    style={{
                      color: "#4D71F7",
                    }}
                    onChange={handleChange}
                    size="medium"
                    checked={checked}
                  />
                </div>
                <strong>
                  I understand the way in which my personal data is going to be
                  used as set out in this statement and the long form privacy
                  notice
                </strong>
              </p>
            )}
          </div>

          {user && isUser && (
            <div className="agreement-buttons">
              <button
                className="secondary"
                onClick={() => {
                  firebase
                    .auth()
                    .signOut()
                    .then(() => {
                      history.goBack();
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
                disabled={false}
              >
                Decline
              </button>

              <button
                className="primary"
                onClick={() => {
                  history.push(`/${projectName}/onboarding`);
                }}
                disabled={!checked}
              >
                I have read and accept the terms and conditions
              </button>
            </div>
          )}
        </div>
      </div>

      <FooterV2 projectName={projectName} hideLinks={hideLinks} />
    </>
  );
};

export default PrivacyPolicy;
