import React, { memo } from "react";
import PropTypes from "prop-types";
import goodJobIcon from "../../assets/icons/good-job.svg";
import wellDoneIcon from "../../assets/icons/well-done.svg";
import { currentSection, stringifyNumber } from "../../utils";
import { Feedback } from "../";

const AssessmentFeedback = (props) => {
  const {
    activeQuestion,
    assessmentQuestions,
    closeFeedbackMessage,
    completedAssessment,
  } = props;

  let progressText = "";

  switch (currentSection(activeQuestion, assessmentQuestions) - 1) {
    case 1:
      progressText = "a quarter of the";
      break;

    case 2:
      progressText = "half";
      break;

    case 3:
      progressText = "three quarters of the";
      break;

    default:
      break;
  }

  return (
    <>
      {currentSection(activeQuestion, assessmentQuestions) !== 5 ? (
        <Feedback
          closeFeedbackMessage={closeFeedbackMessage}
          icon={goodJobIcon}
          buttonText="Next"
        >
          <h2>
            Good job!
            <br />
            You’ve completed the{" "}
            {stringifyNumber(
              currentSection(activeQuestion, assessmentQuestions) - 1
            )}{" "}
            section
          </h2>
          <p>
            You are {progressText} way there! The questions you are answering
            help us identify your understanding and perceptions of equality in
            the workplace.
          </p>
        </Feedback>
      ) : (
        <Feedback
          closeFeedbackMessage={completedAssessment}
          icon={wellDoneIcon}
          buttonText="View report"
        >
          <h2>All done!</h2>
          <p className="selector feedback-subtitle">
            The following report provides insight based on your responses.
          </p>
          <div className="feedback-body">
            <p className="selector">
              <strong>What to expect from the report?</strong>
            </p>
            <p>
              When considering your report’s description of your personality, it
              is important to recognise that it is based on the answers you gave
              and is your own view, representing the way you see your behaviour,
              rather than how your personality might be described by another
              person.
              <br />
              <br />
              Self-reporting can nevertheless reveal important clues about the
              way you build cultures of equality at work and your inclusive
              leadership style.
              <br />
              <br />
              This report summarises all of your responses to the questionnaire
              under three broad headings.The specific application of the
              information will influence which sections of the report are most
              relevant.
              <br />
              <br />
              Any feedback or guidance you receive based on the results of this
              report should be delivered by a qualified individual who can
              provide a more detailed analysis of what the results mean for you.
              <br />
              <br />
              The contents of this report are likely to be a good description of
              your behaviour at work for about 18 to 24 months, depending upon
              your work role and personal circumstances. If it is to be used in
              the future, consideration should be given to its continued
              relevance.
            </p>
          </div>
        </Feedback>
      )}
    </>
  );
};

AssessmentFeedback.propTypes = {
  activeQuestion: PropTypes.number,
  assessmentQuestions: PropTypes.array,
  closeFeedbackMessage: PropTypes.func,
  completedAssessment: PropTypes.func,
};

AssessmentFeedback.defaultProps = {
  activeQuestion: 0,
  assessmentQuestions: [],
  closeFeedbackMessage: () => {},
  completedAssessment: () => {},
};

export default memo(AssessmentFeedback);
