import React, { useState, memo, useEffect } from "react";
import _ from "lodash";

import { isUnique } from "../../utils";

import firebase from "../../firebase";

import { UsersContainer, ReportSelected, FooterV2 } from "../../components";

export const Users = (props) => {
  const { history, admin, match } = props;
  const [companyFilter, setCompanyFilter] = useState("");
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [viewReport, setViewReport] = useState(false);
  const [liveVersion, setLiveVersion] = useState(null);
  const { projectName } = match.params;

  const handleChange = (event) => {
    setCompanyFilter(event.target.value);
    setSelected([]);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const db = firebase.firestore();

    const allUsersData = db
      .collection(projectName)
      .doc("users")
      .collection("user")
      .doc("all");

    const unsubscribe = allUsersData.onSnapshot((doc) => {
      if (doc.data()) {
        const firebaseUsers = Object.values(doc.data());

        if (Object.keys(doc.data()).length > 0) {
          if (!_.isEqual(firebaseUsers, users)) {
            setUsers(firebaseUsers);
          }
        } else {
          setUsers([]);
        }
      }
    });

    return () => {
      // Unmouting
      unsubscribe();
    };
  }, []);

  const companiesAvailable = () => {
    const allCompanies = users.map(({ company }) => {
      return company;
    });

    return isUnique(allCompanies);
  };

  return (
    <>
      <div className="page">
        {viewReport ? (
          <ReportSelected
            companyFilter={companyFilter}
            companiesAvailable={companiesAvailable}
            handleChange={handleChange}
            history={history}
            selectedUsersObj={selected}
            setSelected={setSelected}
            setViewReport={setViewReport}
            admin={admin}
            match={match}
            projectName={projectName}
            assessmentVersion={liveVersion}
            users={users}
          />
        ) : (
          <UsersContainer
            selected={selected}
            companyFilter={companyFilter}
            companiesAvailable={companiesAvailable}
            handleChange={handleChange}
            history={history}
            users={users}
            setSelected={setSelected}
            setViewReport={setViewReport}
            projectName={projectName}
            assessmentVersion={liveVersion}
          />
        )}
      </div>
      <FooterV2 projectName={projectName} />
    </>
  );
};

export default memo(Users);
