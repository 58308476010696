import React, { useState, useEffect } from "react";
import firebase from "../../firebase";
import logo from "../../assets/img/logo-colour.svg";
import person1 from "../../assets/img/person-1.svg";
import person2 from "../../assets/img/person-2.svg";
import person3 from "../../assets/img/person-3.svg";
import person4 from "../../assets/img/person-4.svg";
import { Link } from "react-router-dom";

import { Checkbox } from "@material-ui/core";

const Login = ({
  isAdmin,
  setIsAdmin,
  setIsUser,
  history,
  setIsLoading,
  match,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [error, setError] = useState("");
  const { projectName } = match.params;

  const signIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {})
      .catch((error) => {
        setError(error.message);
        console.error(error);
      });
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              history.push(`/${projectName}/users`);
            } else if (claims.isUser) {
              history.push(`/${projectName}/user/${user.uid}`);
            } else {
              history.push(`/${projectName}/forbidden`);
            }

            setIsLoading(false);
          })
          .catch((e) => {
            setIsLoading(false);
            console.error(e);
          });
      } else {
        setIsLoading(false);
      }
    });
  }, [history, setIsLoading]);

  return (
    <>
      <div className="login">
        <div className="container">
          <div>
            <img
              src={logo}
              alt="Leadership Equality Index"
              className="project-logo"
            />

            <div className="content">
              <h1>Welcome to the Leadership Equality Index</h1>
              <p className="selector">
                Enabling leaders to put equality into practice.
              </p>

              <p>
                You will need an email account registered with the Leadership
                Equality Index to access.
                <br />
                <br />
                If your employer has signed-up, you will have received an email
                invitation. Please enter the email address the invitation was
                sent to.
              </p>
              <label>Email address</label>
              <input
                value={email}
                maxLength="45"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <label>Password</label>
              <input
                value={password}
                maxLength="45"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                autoComplete="on"
                type={!showPassword ? "password" : "text"}
              />

              <p className="error">{error}</p>

              <div>
                <p>
                  <Checkbox
                    checked={showPassword}
                    style={{
                      color: "#4D71F7",
                      padding: "9px 9px 9px 9px",
                      marginLeft: "-9px",
                    }}
                    onChange={() => setshowPassword(!showPassword)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  Show Password
                </p>
              </div>

              <button className="primary" onClick={signIn} disabled={false}>
                Login
              </button>
            </div>
          </div>
          <div>
            <p>
              Find out more about this service at our website{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.equalityforward.com"
              >
                https://www.equalityforward.com
              </a>
            </p>

            <p>
              Terms of service:{" "}
              <Link to="privacy-policy-long">Privacy Policy</Link> |{" "}
              <Link to="cookie-policy">Cookie Policy</Link>
            </p>
          </div>
        </div>
        <div className="container"></div>
      </div>
    </>
  );
};

export default Login;
