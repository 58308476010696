import React, { useState } from "react";

import {
  KeyboardArrowUp,
  KeyboardArrowLeft,
  Assessment,
} from "@material-ui/icons";

import { FooterV2 } from "../../components";

const ReportAbout = (props) => {
  const { history, match } = props;
  const { projectName } = match.params;

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  const hideLinks =
    match.url.includes("faq") ||
    match.url.includes("cookie-policy") ||
    match.url.includes("privacy-policy");

  return (
    <>
      <div className="page">
        <button
          className="secondary back"
          onClick={() => history.goBack()}
          disabled={false}
        >
          <KeyboardArrowLeft />
          Back
        </button>
        <div className="report-about">
          <Assessment />
          <h1 className="title">The Leadership Equality Index</h1>
          <p className="sub-title subtitle ">
            <strong>
              In today’s ever-changing work environment, we need leaders who can
              create cultures that value difference and harness diversity to
              innovate, create and advance the organisations aims. Organisations
              that are dedicated to creating this environment invest in
              developing their leaders. One of these development tools is the
              Leadership Equality Index (LEI).
            </strong>
          </p>
          <div className="body">
            <p>
              The LEI is a self- assessment diagnostic tool, which measures what
              leaders are really doing to create a culture of equality in their
              organisation. It provides a deep dive assessment into the three
              key foundational behaviours of an inclusive leader, these include
              awareness, understanding and practices that advance equality.
              <br />
              <br />
              This report was generated using LEI online assessment system. It
              includes information from the LEI survey. The use of this
              questionnaire is limited to those people who have received
              specialist training in its use and interpretation. The report
              herein is generated from the results of a questionnaire answered
              by the respondent(s) and substantially reflects the answers made
              by them.
              <br />
              <br />
              Due consideration must be given to the subjective nature of
              questionnaire-based ratings in the interpretation of this data.
              This report has been generated electronically – the user of the
              software can make amendments and additions to the text of the
              report. Equality Forward Consulting Limited and its associated
              companies cannot guarantee that the contents of this report are
              the unchanged output of the computer system. We can accept no
              liability for the consequences of the use of this report and this
              includes liability of every kind (including negligence) for its
              contents.
              <br />
              <br />© Equality Forward Consulting Limited 2020. All rights
              reserved. the LEI is a trademark of Equality Forward Consulting
              Limited which is registered in the United Kingdom and other
              countries. This report has been produced by Equality Forward
              Consulting Limited for the benefit of its client and contains
              Equality Forward Consulting Limited intellectual property. As
              such, Equality Forward Consulting Limited permits its its clients
              to reproduce, distribute, amend and store this report for its
              internal and non-commercial use only. All other rights of Equality
              Forward Consulting Limited are reserved.
            </p>
            <p className="further-information">
              <strong>
                Find out more about this service at our website Further
                information on rejection and deletion of cookies is provided at{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.equalityforward.com"
                >
                  https://www.equalityforward.com
                </a>
              </strong>
            </p>
          </div>
          <button
            className="secondary see-more"
            onClick={scrollTop}
            disabled={false}
          >
            <KeyboardArrowUp />
            Go to the top
          </button>
        </div>
      </div>

      <FooterV2 projectName={projectName} hideLinks={hideLinks} />
    </>
  );
};

export default ReportAbout;
