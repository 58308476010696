import firebase from "../firebase";
import * as _ from "lodash";

const special = [
  "zeroth",
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth",
];
const deca = [
  "twent",
  "thirt",
  "fort",
  "fift",
  "sixt",
  "sevent",
  "eight",
  "ninet",
];

export const stringifyNumber = (n) => {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
};

export const currentSection = (activeQuestion, questions) => {
  const percent25 = Math.round(questions.length / 4);
  const percent50 = Math.round(percent25 * 2);
  const percent75 = Math.round(percent25 * 3);
  const percent100 = Math.round(percent25 * 4);

  if (activeQuestion >= 0 && activeQuestion < percent25) {
    return 1;
  } else if (activeQuestion >= percent25 && activeQuestion < percent50) {
    return 2;
  } else if (activeQuestion >= percent50 && activeQuestion < percent75) {
    return 3;
  } else if (activeQuestion >= percent75 && activeQuestion < percent100) {
    return 4;
  } else {
    return 5;
  }
};

export const isUnique = (values) =>
  values.filter((v, i) => values.indexOf(v) === i);

export const isLogin = () => {};

export const addingAndToArray = (array) => {
  return array.map(({ name }, index) => {
    return index + 1 === array.length
      ? ` and ${name}`
      : `${name}${index > 1 ? "," : ""}`;
  });
};

export const shuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const sortSection = (sections) => {
  return sections.sort((a, b) => {
    if (a.section.toLowerCase() < b.section.toLowerCase())
      //sort string ascending
      return -1;
    if (a.section.toLowerCase() > b.section.toLowerCase()) return 1;
    return 0; //default return value (no sorting)
  });
};

export const sectionCalculations = (
  sectionsNormGroup,
  section,
  selectedUsers,
  isUserReport,
  userAssessmentData
) => {
  const selectedUsersAverageSubSectionsScores = Object.keys(
    sectionsNormGroup[section]
  )
    .sort()
    .map((subSection) => {
      const normGroupSubSection = sectionsNormGroup[section][subSection];
      let selectedUsersScores = [];

      if (isUserReport) {
        selectedUsersScores = [
          userAssessmentData &&
            userAssessmentData[section] &&
            userAssessmentData[section][subSection],
        ];
      } else {
        selectedUsers.forEach((selectedUserId) => {
          if (normGroupSubSection[selectedUserId]) {
            selectedUsersScores.push(normGroupSubSection[selectedUserId]);
          }
        });
      }

      return averageCalculator(selectedUsersScores);
    });

  const normGroupAverageSubSectionsScores = Object.keys(
    sectionsNormGroup[section]
  )
    .sort()
    .map((subSection) => {
      const normGroupSubSection = sectionsNormGroup[section][subSection];
      return Object.keys(normGroupSubSection)
        .sort()
        .map((normGroupSubSec) => {
          return normGroupSubSection[normGroupSubSec];
        });
    });

  const selectedUsersAverageSectionScore = averageCalculator(
    selectedUsersAverageSubSectionsScores
  );
  const normGroupSize = normGroupAverageSubSectionsScores[0].length;
  let allUsersAverageSectionScore = [];

  for (let index = 0; index < normGroupSize; index++) {
    allUsersAverageSectionScore.push(
      averageCalculator(getCol(normGroupAverageSubSectionsScores, index))
    );
  }

  let numberOfScoresBelowSelectedUsers = 0;
  allUsersAverageSectionScore.forEach((score) => {
    if (score <= selectedUsersAverageSectionScore)
      numberOfScoresBelowSelectedUsers += 1;
  });

  const sectionPercentileRank = Math.round(
    (numberOfScoresBelowSelectedUsers / allUsersAverageSectionScore.length) *
      100
  );

  return sectionPercentileRank;
};

export const subSectionCalculations = (
  sectionsNormGroup,
  section,
  subSection,
  selectedUsers
) => {
  const normGroupSubSection = sectionsNormGroup[section][subSection];
  const normGroupSubSectionsAverageScores = Object.keys(normGroupSubSection)
    .sort()
    .map((normGroupSubSec) => {
      return normGroupSubSection[normGroupSubSec];
    });

  const selectedUsersScores = [];

  selectedUsers.forEach((selectedUserId) => {
    return normGroupSubSection[selectedUserId]
      ? selectedUsersScores.push(normGroupSubSection[selectedUserId])
      : true;
  });

  let numberOfScoresBelowSelectedUsers = 0;
  normGroupSubSectionsAverageScores.forEach((score) => {
    if (score <= averageCalculator(selectedUsersScores))
      numberOfScoresBelowSelectedUsers += 1;
  });

  const subSectionPercentileRank = Math.round(
    (numberOfScoresBelowSelectedUsers /
      normGroupSubSectionsAverageScores.length) *
      100
  );

  return subSectionPercentileRank;
};

export const userSubSectionCalculations = (
  sectionsNormGroup,
  section,
  subSection,
  subSectionUserScore
) => {
  const normGroupSubSection = sectionsNormGroup[section][subSection];
  const normGroupSubSectionsAverageScores = Object.keys(normGroupSubSection)
    .sort()
    .map((normGroupSubSec) => {
      return normGroupSubSection[normGroupSubSec];
    });

  let numberOfScoresBelowSelectedUsers = 0;
  normGroupSubSectionsAverageScores.forEach((score) => {
    if (score <= averageCalculator([subSectionUserScore]))
      numberOfScoresBelowSelectedUsers += 1;
  });

  const subSectionPercentileRank = Math.round(
    (numberOfScoresBelowSelectedUsers /
      normGroupSubSectionsAverageScores.length) *
      100
  );

  return subSectionPercentileRank;
};

export const sortSubsection = (subSections) => {
  return subSections.sort((a, b) => {
    if (a.subSection.toLowerCase() < b.subSection.toLowerCase())
      //sort string ascending
      return -1;
    if (a.subSection.toLowerCase() > b.subSection.toLowerCase()) return 1;
    return 0; //default return value (no sorting)
  });
};

export const sendMagicLink = (email, projectName) => {
  firebase
    .auth()
    .currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      /**
       * CHECK CURRENT USER IS ADMIN
       */
      const {
        claims: { isAdmin },
      } = idTokenResult;

      if (isAdmin) {
        /**
         * SEND EMAIL LINK
         */
        const sendEmailLinkInvitation = firebase
          .functions()
          .httpsCallable("sendEmailLink");

        sendEmailLinkInvitation({ email, projectName })
          .then(() => {
            console.log("Magic link has been sent!");
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        throw new Error("Not authorised to perform request");
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

export const msToTime = (durationTime) => {
  let minutes = Math.floor((durationTime / (1000 * 60)) % 60),
    hours = Math.floor((durationTime / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${hours !== "00" ? `${hours}hr` : ""} ${
    minutes ? `${minutes}mins` : ""
  }`;
};

export const feedbackDetails = (percent) => {
  let feedback = "";
  let feedbackColor = "";
  let feedbackScore = "";

  if (percent > 60 && percent <= 100) {
    feedbackScore = "above average";
    feedback = "Great Job!";
    feedbackColor = "#23BB86";
  } else if (percent > 20 && percent <= 60) {
    feedbackScore = "average";
    feedback = "Growth Opportunity";
    feedbackColor = "#F2994A";
  } else {
    // percent < 20
    feedbackScore = "below average";
    feedback = "Development need";
    feedbackColor = "#D00C00";
  }

  return {
    feedbackScore,
    feedback,
    feedbackColor,
  };
};

export const getCol = (matrix, col) => {
  var column = [];
  for (var i = 0; i < matrix.length; i++) {
    column.push(matrix[i][col]);
  }
  return column;
};

export const averageCalculator = (array) => {
  const sum = array.reduce((a, b) => a + b, 0);
  const avg = sum / array.length || 0;

  return Number(avg.toFixed(1));
};

export const answersFromSubSection = (currentSubSection) => {
  /*
  returns a matrix of answers from subSection (each index item is an assessment average of all questions in subsection per user)
  **/
  const groupedByQuestions = _.groupBy(currentSubSection, function (b) {
    return b.question;
  });

  /*
  scoresOfAllQuestions -> is an array of all questions in a subsection with an array of all answers
  **/
  const scoresOfAllQuestions = Object.keys(groupedByQuestions)
    .sort()
    .map((questionKey) => {
      const currentQuestion = groupedByQuestions[questionKey];
      const questionAnswers = currentQuestion.map((answer) => {
        return answer.score;
      });

      return questionAnswers;
    });

  return scoresOfAllQuestions;
};

export const subSectionData = (currentSubSection) => {
  const averageScoreOfSubSection = [];
  const arrayAnswersFromSubSection = answersFromSubSection(currentSubSection);

  for (let index = 0; index < arrayAnswersFromSubSection[0].length; index++) {
    averageScoreOfSubSection.push(
      averageCalculator(getCol(arrayAnswersFromSubSection, index))
    );
  }

  return {
    averageScoreOfSubSection,
    scoresOfAllQuestions: arrayAnswersFromSubSection,
  };
};

export const groupData = (assessmentAnswers) => {
  const grouped = _.groupBy(
    assessmentAnswers.length > 0 && assessmentAnswers,
    ({ subSection }) => {
      return subSection;
    }
  );

  const averageScoreOfSubSection = Object.keys(grouped)
    .sort()
    .map((answers) => {
      let totalSum = 0;

      grouped[answers].forEach(({ score }) => {
        totalSum += score;
      });

      const average = totalSum / grouped[answers].length;

      return {
        section: grouped[answers][0].section,
        subSection: answers,
        averageScore: Math.round(average * 10) / 10,
      };
    });

  const groupedBySection = _.groupBy(averageScoreOfSubSection, (b) => {
    return b.section;
  });

  const assessmentData = Object.keys(groupedBySection)
    .sort()
    .map((section) => {
      const currentSection = groupedBySection[section];

      const subSectionsArray = currentSection.map((subSection) => {
        const picked = (({ averageScore, subSection }) => ({
          averageScore,
          subSection,
        }))(subSection);

        return picked;
      });

      return {
        section,
        subSections: subSectionsArray,
      };
    });

  return assessmentData;
};
