import React, { useState, memo, useEffect } from "react";

import firebase from "../../firebase";

import { Results, ResultsHeader, PDFdocument } from "../../components";
import { ExportToCsv } from "export-to-csv";
import _ from "lodash";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { Tooltip, Modal } from "@material-ui/core";
import { sortSection, subSectionCalculations } from "../../utils";
import { KeyboardArrowLeft, GetApp } from "@material-ui/icons";

export const ReportSelected = (props) => {
  const {
    match,
    admin,
    projectName,
    selectedUsersObj,
    setViewReport,
    assessmentVersion,
    users,
  } = props;
  const [activeSection, setActiveSection] = useState("summary");
  const [sectionsNormGroup, setSectionsNormGroup] = useState({});
  const [sectionsSummary, setSectionsSummary] = useState([]);
  const [subSectionsFeedback, setSubSectionsFeedback] = useState([]);
  const [sortedByName, setSortedByName] = useState([]);
  const [usersCompletedAssessment, setUsersCompletedAssessment] = useState(0);
  const [show, hide] = useState(false);
  const [downloadModal, showDownloadModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { timeStamp } = match.params;

  useEffect(()=>{
    setSelectedUsers(selectedUsersObj.map(({ uid }) => uid));
  },[selectedUsersObj]);

  useEffect(() => {
    const db = firebase.firestore();
    if (assessmentVersion && selectedUsers.length > 0) {
      const allAssessments = db
        .collection(projectName)
        .doc("assessments")
        .collection(assessmentVersion)
        .doc("all");

      const unsubscribeAssessments = allAssessments.onSnapshot((doc) => {
        if (doc.exists) {
          const sectionNormGroup = doc.data().assessment;
          setSectionsNormGroup(sectionNormGroup);
          const resultsData = [];

          sectionNormGroup &&
            Object.keys(sectionNormGroup).length > 0 &&
            Object.keys(sectionNormGroup)
              .sort()
              .forEach((section) => {
                Object.keys(sectionNormGroup[section])
                  .sort()
                  .forEach((subSection) => {
                    const subsectionPercentileRank = subSectionCalculations(
                      sectionNormGroup,
                      section,
                      subSection,
                      selectedUsers
                    );

                    const emails = selectedUsersObj.map(({ email }) => email);
                    const names = selectedUsersObj.map(({ displayName }) => displayName);
                    const companies = selectedUsersObj.map(({ company }) => company);

                    resultsData.push({
                      Company: _.uniqBy(companies,).sort().toString(),
                      Name: names.sort().toString(),
                      Email: emails.sort().toString(),
                      Section: section,
                      Subsection: subSection,
                      "Percentile rank": subsectionPercentileRank,
                    });
                  });
              });

          const sortedByNames = resultsData.sort((a, b) => {
            if (a["Name"].toLowerCase() < b["Name"].toLowerCase())
              //sort string ascending
              return -1;
            if (a["Name"].toLowerCase() > b["Name"].toLowerCase()) return 1;
            return 0; //default return value (no sorting)
          });

          setSortedByName(sortedByNames);
        } else {
          setSectionsNormGroup({});
        }
      });

      const currentAssesmentVersion = db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .doc(assessmentVersion)
        .collection("assessment")
        .doc("sections")
        .get();

      currentAssesmentVersion.then((doc) => {
        const sectionKeys = Object.keys(doc.data()).sort();

        const sectionsFeedback = sectionKeys.map((sectionKey) => {
          return {
            body: doc.data()[sectionKey].body,
            section: sectionKey,
          };
        });

        const subsectionsFeedback = sectionKeys.map((sectionKey) => {
          return Object.keys(doc.data()[sectionKey].subsections)
            .sort()
            .map((subsectionKey) => {
              return {
                feedback: doc.data()[sectionKey].subsections[subsectionKey]
                  .feedback,
                subSection: subsectionKey,
              };
            });
        });

        if (projectName.includes("leadershipEqualityIndex")) {
          const awarenessSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Awareness")
          );
          const practiceSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Practice")
          );
          const understandingSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Understanding")
          );

          setSectionsSummary([
            awarenessSummary,
            understandingSummary,
            practiceSummary,
          ]);
        } else {
          setSectionsSummary(sortSection(sectionsFeedback));
        }

        setSubSectionsFeedback(subsectionsFeedback.flat());
      });

      hide(true);

      return () => {
        // Unmouting
        unsubscribeAssessments();
      };
    }
  }, [assessmentVersion, selectedUsers]);

  useEffect(() => {
    sectionsNormGroup &&
      Object.keys(sectionsNormGroup).length > 0 &&
      Object.keys(sectionsNormGroup)
        .sort()
        .forEach((section) => {
          const selectedUsersScores = [];
          Object.keys(sectionsNormGroup[section])
            .sort()
            .forEach((subSection) => {
              const normGroupSubSection =
                sectionsNormGroup &&
                sectionsNormGroup[section] &&
                sectionsNormGroup[section][subSection];

              selectedUsers.forEach((selectedUserId) => {
                if (
                  !selectedUsersScores.includes(selectedUserId) &&
                  normGroupSubSection &&
                  normGroupSubSection[selectedUserId]
                ) {
                  selectedUsersScores.push(selectedUserId);
                }
              });
            });
          setUsersCompletedAssessment(
            Object.values(selectedUsersScores).length
          );
        });
  }, [sectionsNormGroup, selectedUsers]);

  const selectSection = (event, section) => {
    if (section !== null) {
      setActiveSection(section);
    }
  };

  const resultsAvailable =
    activeSection &&
    selectedUsers &&
    typeof sectionsNormGroup === "object" &&
    usersCompletedAssessment > 0;

  let normGroupData = sectionsNormGroup;

  if (
    sectionsNormGroup &&
    sectionsNormGroup.Awareness &&
    sectionsNormGroup.Understanding &&
    sectionsNormGroup.Practice
  ) {
    normGroupData = {
      Awareness: sectionsNormGroup.Awareness,
      Understanding: sectionsNormGroup.Understanding,
      Practice: sectionsNormGroup.Practice,
    };
  }

  return (
    <>
      <div className="toolbar">
        <div className="toolbar-items">
          {admin && (
            <button
              className="secondary back"
              onClick={() => setViewReport(false)}
              disabled={false}
            >
              <KeyboardArrowLeft />
              Dashboard
            </button>
          )}
        </div>

        <div className="toolbar-items">
          <h1 className="project-title">Leadership Equality Index</h1>
        </div>
        <div className="toolbar-items"></div>
      </div>

      <div className="report-company-container">
        <div className="about-report-header">
          <p>
            <strong>Selected user(s) report</strong>
          </p>
          {resultsAvailable && (
            <button
              className="download-button"
              onClick={() => showDownloadModal(true)}
            >
              Download
              <GetApp />
            </button>
          )}
        </div>
        <div className="details-container">
          <h1>Selected users ({selectedUsers.length})</h1>
          <div className="assessment-stats"></div>

          {usersCompletedAssessment > 0 && (
            <ResultsHeader
              activeSection={activeSection}
              selectSection={selectSection}
              sectionsSummary={sectionsSummary}
            />
          )}
        </div>

        {resultsAvailable ? (
          <Results
            activeSection={activeSection}
            sectionsNormGroup={normGroupData}
            subSectionsFeedback={subSectionsFeedback}
            selectedUsers={selectedUsers}
            match={{
              params: {
                projectName,
                assessmentVersion: assessmentVersion,
              },
            }}
          />
        ) : (
          <p style={{ paddingBottom: "1.5rem" }}>No results available...</p>
        )}
      </div>

      <Modal
        open={downloadModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="modal-container">
          <div className="modal-content">
            <h2>Which download format do you require?</h2>
            {show &&
              sortedByName &&
              sortedByName.length > 0 &&
              subSectionsFeedback.length > 0 &&
              usersCompletedAssessment > 0 && (
                <>
                  <div>
                    <Tooltip
                      title="Download all selected user(s) percentile ranks"
                      aria-label="Download all selected user(s) percentile ranks"
                    >
                      <button
                        className="tertiary action-button"
                        onClick={() => {
                          const options = {
                            fieldSeparator: ",",
                            quoteStrings: '"',
                            decimalSeparator: ".",
                            showLabels: true,
                            showTitle: true,
                            title:
                              "All selected user(s) last completed assessment percentile ranks",
                            useTextFile: false,
                            useBom: true,
                            useKeysAsHeaders: true,
                          };

                          const csvExporter = new ExportToCsv(options);

                          csvExporter.generateCsv(sortedByName);
                        }}
                        disabled={false}
                      >
                        <strong>.csv</strong>
                      </button>
                    </Tooltip>

                    <PDFDownloadLink
                      document={
                        <PDFdocument
                          data={sortedByName}
                          feedback={subSectionsFeedback}
                          sectionsSummary={sectionsSummary}
                        />
                      }
                      fileName="results.pdf"
                      style={{
                        textDecoration: "none",
                        color: "#4d71f7",
                        background: "white",
                        margin: "0.5rem 0.5rem 0.5rem 0",
                        width: "3rem",
                        fontSize: "16px",
                        fontFamily: "HK Grotesk",
                        fontWeight: "bold",
                        lineHeight: "140%",
                        height: "3rem",
                        position: "relative",
                        borderRadius: "4px",
                        display: "inline-flex",
                        border: "1px solid #c9c9c9",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {({ loading }) => (loading ? ".pdf" : ".pdf")}
                    </PDFDownloadLink>
                  </div>
                </>
              )}

            <div className="cta-container">
              <button
                className="primary action-button"
                style={{ color: "white" }}
                onClick={() => {
                  showDownloadModal(false);
                }}
                disabled={false}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(ReportSelected);
