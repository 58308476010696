import React, { useState, memo, useEffect } from "react";
import * as moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Link } from "react-router-dom";

import firebase from "../../firebase";

import {
  msToTime,
  sortSection,
  sortSubsection,
  userSubSectionCalculations,
} from "../../utils";
import { ExportToCsv } from "export-to-csv";

import { Tooltip, Modal } from "@material-ui/core";
import {
  Results,
  ResultsHeader,
  PDFdocument,
  FooterV2,
} from "../../components";

import { KeyboardArrowLeft, ChevronRight, GetApp } from "@material-ui/icons";

import _ from "lodash";

export const ReportUser = (props) => {
  const { history, match, admin } = props;
  const [activeSection, setActiveSection] = useState("summary");
  const [userAssessmentData, setUserAssessmentData] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([match.params.userId]);
  const [sectionsNormGroup, setSectionsNormGroup] = useState([]);
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  // const [duration, setDuration] = useState("");
  const [date, setDate] = useState("");
  const [sectionsSummary, setSectionsSummary] = useState([]);
  const [subSectionsFeedback, setSubSectionsFeedback] = useState([]);
  const { projectName, userId, timeStamp } = match.params;
  const [sortedByName, setSortedByName] = useState([]);
  const [show, hide] = useState(false);
  const [liveVersion, setLiveVersion] = useState(null);
  const [downloadModal, showDownloadModal] = useState(false);

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (sectionsNormGroup && name && company && email) {
      const resultsData = [];

      sectionsNormGroup &&
        Object.keys(sectionsNormGroup).length > 0 &&
        Object.keys(sectionsNormGroup)
          .sort()
          .forEach((section) => {
            Object.keys(sectionsNormGroup[section])
              .sort()
              .forEach((subSection) => {
                const subsectionPercentileRank = userSubSectionCalculations(
                  sectionsNormGroup,
                  section,
                  subSection,
                  userAssessmentData[section][subSection]
                );

                resultsData.push({
                  Company: company,
                  Name: name,
                  Email: email,
                  Section: section,
                  Subsection: subSection,
                  "Percentile rank": subsectionPercentileRank,
                });
              });
          });

      const sortedByNameData = resultsData.sort((a, b) => {
        if (a["Name"].toLowerCase() < b["Name"].toLowerCase())
          //sort string ascending
          return -1;
        if (a["Name"].toLowerCase() > b["Name"].toLowerCase()) return 1;
        return 0; //default return value (no sorting)
      });

      setSortedByName(sortedByNameData);
    }
  }, [sectionsNormGroup, name, email, company]);

  useEffect(() => {
    if (liveVersion) {
      const db = firebase.firestore();
      const allAssessments = db
        .collection(projectName)
        .doc("assessments")
        .collection(liveVersion)
        .doc("all");

      const unsubscribe = allAssessments.onSnapshot((doc) => {
        const sectionNormGroup = doc.data() && doc.data().assessment;
        setSectionsNormGroup(sectionNormGroup);
      });

      const assessments = db
        .collection(projectName)
        .doc("assessments")
        .collection(liveVersion)
        .doc(userId);

      const unsubscribeassessments = assessments.onSnapshot((doc) => {
        // setDuration(
        //   doc.data()[timeStamp].assessmentTimestampEnd -
        //     doc.data()[timeStamp].assessmentTimestampStart
        // );

        if (
          doc.data() &&
          doc.data()[timeStamp] &&
          doc.data()[timeStamp].assessmentTimestampStart
        ) {
          setDate(doc.data()[timeStamp].assessmentTimestampStart);
        }

        if (
          doc.data() &&
          doc.data()[timeStamp] &&
          doc.data()[timeStamp].assessment
        ) {
          setUserAssessmentData(doc.data()[timeStamp].assessment);
        }
      });

      const userData = db
        .collection(projectName)
        .doc("users")
        .collection("user")
        .doc(userId);

      const unsubscribeUserData = userData.onSnapshot((doc) => {
        if (doc.data() && doc.data().displayName) {
          setName(
            doc.data().displayName ? doc.data().displayName : "Anonymous"
          );
          setEmail(doc.data().email);
          setCompany(doc.data().company);
          setSelectedUsers([userId]);
        }
      });

      const currentAssesmentVersion = db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .doc(liveVersion)
        .collection("assessment")
        .doc("sections")
        .get();

      currentAssesmentVersion.then((doc) => {
        const sectionKeys = Object.keys(doc.data()).sort();

        const sectionsFeedback = sectionKeys.map((sectionKey) => {
          return {
            body: doc.data()[sectionKey].body,
            section: sectionKey,
          };
        });

        const subsectionsFeedback = sectionKeys.map((sectionKey) => {
          return Object.keys(doc.data()[sectionKey].subsections)
            .sort()
            .map((subsectionKey) => {
              return {
                feedback: doc.data()[sectionKey].subsections[subsectionKey]
                  .feedback,
                subSection: subsectionKey,
              };
            });
        });

        if (projectName.includes("leadershipEqualityIndex")) {
          const awarenessSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Awareness")
          );
          const practiceSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Practice")
          );
          const understandingSummary = sectionsFeedback.find(({ section }) =>
            section.includes("Understanding")
          );

          setSectionsSummary([
            awarenessSummary,
            understandingSummary,
            practiceSummary,
          ]);
        } else {
          setSectionsSummary(sortSection(sectionsFeedback));
        }
        setSubSectionsFeedback(sortSubsection(subsectionsFeedback.flat()));
      });

      hide(true);

      return () => {
        // Unmouting
        unsubscribe();
        unsubscribeUserData();
        unsubscribeassessments();
      };
    }
  }, [liveVersion]);

  const selectSection = (event, section) => {
    if (section !== null) {
      setActiveSection(section);
    }
  };

  const resultsAvailable =
    activeSection &&
    selectedUsers &&
    typeof sectionsNormGroup === "object" &&
    Object.keys(sectionsNormGroup).length > 0;

  let normGroupData = sectionsNormGroup;

  if (
    sectionsNormGroup &&
    sectionsNormGroup.Awareness &&
    sectionsNormGroup.Understanding &&
    sectionsNormGroup.Practice
  ) {
    normGroupData = {
      Awareness: sectionsNormGroup.Awareness,
      Understanding: sectionsNormGroup.Understanding,
      Practice: sectionsNormGroup.Practice,
    };
  }

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => history.push(`/${projectName}/user/${userId}`)}
              disabled={false}
            >
              <KeyboardArrowLeft />
              Dashboard
            </button>
          </div>

          <div className="toolbar-items">
            <h1 className="project-title">Leadership Equality Index</h1>
            {!admin && (
              <p className="small learn-more">
                <Link to={`/${projectName}/report-about`}>
                  Learn more
                  <ChevronRight />
                </Link>
              </p>
            )}
          </div>

          <div className="toolbar-items download-csv-container"></div>
        </div>

        <div className="about-report-container">
          <div className="about-report-header">
            <p>
              <strong>Your report</strong>
            </p>

            {resultsAvailable && (
              <div style={{ display: "flex" }}>
                <label style={{ paddingRight: "1rem", alignItems: "center" }}>
                  {moment(date).format("DD/MM/YYYY")}
                </label>
                  <button
                    className="download-button"
                    onClick={() => showDownloadModal(true)}
                  >
                    Download
                    <GetApp />
                  </button>
              </div>
            )}
          </div>
          <div className="about-report-body">
            <div>
              <p>
                <strong>What does it tell me?</strong>
              </p>
              <p>
                The questions you answered assesses your behaviour, preferences,
                and attitudes in relation to equality at work. Your responses
                have been compared with a large group of people who have
                answered the same questionnaire. When considering this report’s
                description of your approach to equality at work, it is
                important to recognise that it is based on the answers you gave
                and is your own view, representing the way you see your
                behaviour, rather than how you might be described by another
                person.
              </p>
            </div>
            <div>
              <p>
                <strong>My results</strong>
              </p>
              <p>
                The report can give important clues to understand the way you
                build cultures of equality at work and it is likely to enable us
                to predict a good deal about your inclusive leadership style.
                This report links the information under three broad headings,
                awarness, understanding and practice. You can see your results
                summarised below or click on the tabs for each of the three
                sections for a more detailed report, with reccomendations for
                your development.
              </p>
            </div>
          </div>
          <div style={{ paddingBottom: "1.5rem" }}>
            <p className="small">
              Disclaimer: You will not be able to access your results again.
              It it advised that you download them now.
            </p>
          </div>
        </div>

        <div className="report-user-container" id="divToPrint">
          {/* <div className="time-container">
            <label>Completed at {moment(date).format("LLLL")}</label> 
            <br/>
            <label>Duration of {msToTime(duration)}</label>
          </div> */}

          <div className="details-container">
            <h1>{name}</h1>
            {/* <div className="assessment-stats">
              <div className="assessment-data">
                <label>Date</label>
                <p className="subtitle">
                  {moment(date).format("HH:mmA YYYY-MM-DD")}
                </p>
              </div>
              <div className="assessment-data">
                <label>Duration</label>
                <p className="subtitle">
                  {msToTime(duration)}
                </p>
              </div>
            </div> */}

            {Object.keys(sectionsNormGroup).length > 0 && (
              <ResultsHeader
                activeSection={activeSection}
                selectSection={selectSection}
                sectionsSummary={sectionsSummary}
              />
            )}
          </div>

          {resultsAvailable ? (
            <Results
              activeSection={activeSection}
              sectionsNormGroup={normGroupData}
              subSectionsFeedback={sortSubsection(subSectionsFeedback)}
              selectedUsers={selectedUsers}
              match={match}
              isUserReport={true}
              userAssessmentData={userAssessmentData}
            />
          ) : (
            <p style={{ paddingBottom: "1.5rem" }}>No results available...</p>
          )}
        </div>

        <Modal
          open={downloadModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className="modal-container">
            <div className="modal-content">
              <h2>Which download format do you require?</h2>

              {show &&
                sortedByName &&
                sortedByName.length > 0 &&
                subSectionsFeedback.length > 0 && (
                  <div>
                    <button
                      className="tertiary action-button"
                      onClick={() => {
                        const options = {
                          fieldSeparator: ",",
                          quoteStrings: '"',
                          decimalSeparator: ".",
                          showLabels: true,
                          showTitle: true,
                          title:
                            "User's last completed assessment percentile rank",
                          useTextFile: false,
                          useBom: true,
                          useKeysAsHeaders: true,
                        };

                        const csvExporter = new ExportToCsv(options);

                        csvExporter.generateCsv(sortedByName);
                      }}
                      disabled={false}
                    >
                      <strong>.csv</strong>
                    </button>

                    <PDFDownloadLink
                      document={
                        <PDFdocument
                          data={sortedByName}
                          feedback={subSectionsFeedback}
                          sectionsSummary={sectionsSummary}
                        />
                      }
                      fileName="results.pdf"
                      style={{
                        textDecoration: "none",
                        color: "#4d71f7",
                        background: "white",
                        margin: "0.5rem 0.5rem 0.5rem 0",
                        width: "3rem",
                        fontSize: "16px",
                        fontFamily: "HK Grotesk",
                        fontWeight: "bold",
                        lineHeight: "140%",
                        height: "3rem",
                        position: "relative",
                        borderRadius: "4px",
                        display: "inline-flex",
                        border: "1px solid #c9c9c9",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {({ loading }) => (loading ? ".pdf" : ".pdf")}
                    </PDFDownloadLink>
                  </div>
                )}

              <div className="cta-container">
                <button
                  className="primary action-button"
                  style={{ color: "white" }}
                  onClick={() => {
                    showDownloadModal(false);
                  }}
                  disabled={false}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <FooterV2 projectName={projectName} />
    </>
  );
};

export default memo(ReportUser);
