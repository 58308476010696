import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ExportToCsv } from "export-to-csv";

import {
  Paper,
  InputBase,
  IconButton,
  Tooltip,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import {
  GetApp,
  Search,
  FindInPage,
  Edit,
  Send,
  Delete,
  GroupAdd,
} from "@material-ui/icons";

import { UsersTable } from "../../components";

// import _ from "lodash";
import firebase from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  hover: {},
  headerRowRoot: {
    fontSize: "30px",
  },
  searchInput: {
    width: "100%",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "0",
    fontSize: "16px",
    fontFamily: "HK Grotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "140%",
    outline: "#4D71F7",
    height: "min-content",
  },
  searchInputInput: {
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "0.75rem 1rem 0.75rem 0",
    fontSize: "16px",
    fontFamily: "HK Grotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "140%",
    height: "min-content",
    outline: "#4D71F7",
    // color: '#4D71F7'
  },
  searchInputContainer: {
    padding: "0",
    display: "flex",
    margin: "0.5rem 0.5rem 0.5rem 0",
    border: "1px solid #C9C9C9",
    boxShadow: "none",
    height: "min-content",
    alignItems: "center",
  },
  iconButton: {
    height: "2rem",
    width: "2rem",
    margin: "0 0.5rem",
    padding: "0.75rem 1rem",
    // color: '#4D71F7'
  },
  tableRow: {
    borderBottom: "1px solid #C3D0FC",
    padding: "1rem",
    // cursor: "pointer",
    "&$hover:hover": {
      background: "rgba(195, 208, 252, 0.2)",
    },
    "&$selected, &$selected:hover": {
      background: "rgba(195, 208, 252, 0.2)",
    },
  },
  select: {
    margin: 0,
    padding: 0,
    width: "10rem",
  },
  selectInputBase: {
    margin: "0.5rem 0.5rem 0.5rem 0",
    padding: "0",
    border: "1px solid #C9C9C9",
    height: "min-content",
    display: "flex",
    maxWidth: "20rem",
    boxShadow: "none",
    background: "white",
    fontSize: "16px",
    fontFamily: "HK Grotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    boxSizing: "border-box",
    borderRadius: "4px",
  },
  selectInputBaseInput: {},
  selectSelect: {
    background: "white",
    fontSize: "16px",
    fontFamily: "HK Grotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    boxSizing: "border-box",
    padding: "0.75rem 6rem 0.75rem 1rem",
    borderRadius: "4px",
    lineHeight: "140%",
  },
  selectInput: {
    borderBottom: 0,
    margin: "0",
    background: "#FFFFFF",
    border: "1px solid #C9C9C9",
    boxSizing: "border-box",
    borderRadius: "4px",
    padding: "0.75rem 1rem",
    width: "100%",
    fontSize: "16px",
    fontFamily: "HK Grotesk",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "140%",
    outline: "#4D71F7",
    height: "min-content",
  },
  selected: {},
  rowRoot: {},
  cellRoot: {
    borderBottom: "none",
    padding: "1rem",
    color: "black",
  },
  cellRootLabel: {
    fontSize: "16px",
    fontFamily: "HK Grotesk",
    fontWeight: 700,
    lineHeight: "28px",
  },
  paper: {
    width: "100%",
    boxShadow: "0px 4px 12px #E1E4F0",
    borderRadius: "12px",
  },
  table: {
    minWidth: 750,
  },
  tableBody: {
    borderTop: "2px solid #88A1FA",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export const UsersContainer = (props) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [alerts, setAlerts] = useState([]);

  const {
    selected,
    companyFilter,
    history,
    users,
    setSelected,
    setViewReport,
    projectName,
    assessmentVersion,
  } = props;

  return (
    <div className="users">
      <label>Assessment version: #{assessmentVersion}</label>
      <div className="toolbar">
        <div style={{ width: "100%" }}>
          <label>Search by name, company or email</label>
          <Paper
            component="form"
            classes={{ root: classes.searchInputContainer }}
          >
            <IconButton
              type="submit"
              classes={{ root: classes.iconButton }}
              aria-label="search"
            >
              <Search />
            </IconButton>
            <InputBase
              value={search}
              classes={{
                root: classes.searchInput,
                input: classes.searchInputInput,
              }}
              onChange={(e) => {
                if (e.target.value.length < 35) {
                  setSearch(e.target.value);
                }
              }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
            ></InputBase>
          </Paper>
        </div>

        <div className="filters">
          {selected.length > 0 && (
            <div>
              <label>Selected user(s) actions</label>
              <div className="selected">
                { selected.filter(({pendingResults}) => pendingResults).length > 0 &&
                  <Tooltip
                    title={`Send selected user(s) invite`}
                    aria-label={`Send selected user(s) invite`}
                  >
                    <button
                      className="tertiary action-button"
                      onClick={() => {
                        const invitedEmails = selected.filter(({pendingResults}) => pendingResults)
                        .map(
                          ({ email, uid, displayName }) => {
                            const sendAnotherInvitiation = firebase
                              .functions()
                              .httpsCallable("sendAnotherInvitiation");

                            sendAnotherInvitiation({ uid })
                              .then(() => {
                                const sendEmailLinkInvitation = firebase
                                  .functions()
                                  .httpsCallable("sendEmailLink");

                                sendEmailLinkInvitation({
                                  email,
                                  projectName,
                                  name: displayName,
                                })
                                  .then(() => {
                                    console.log("Magic link has been sent!");
                                  })
                                  .catch((error) => {
                                    console.error(error);
                                  });

                                const db = firebase.firestore();
                                const users = db
                                  .collection(projectName)
                                  .doc("users")
                                  .collection("user")
                                  .doc(String(uid));
                                const allusers = db
                                  .collection(projectName)
                                  .doc("users")
                                  .collection("user")
                                  .doc("all");

                                allusers.set(
                                  {
                                    [String(uid)]: {
                                      pendingResults: true,
                                    },
                                  },
                                  { merge: true }
                                );

                                users.set(
                                  {
                                    pendingResults: true,
                                  },
                                  { merge: true }
                                );
                              })
                              .catch((e) =>
                                console.error(e.message, "e.message")
                              );

                            return `${email} invited`;
                          }
                        );

                        setAlerts(invitedEmails);
                        setTimeout(() => {
                          setAlerts([]);
                        }, 5000);
                      }}
                      disabled={false}
                    >
                      <Send />
                    </button>
                  </Tooltip>
                }

                <Tooltip
                  title={`Delete selected user(s)`}
                  aria-label={`Delete selected user(s)`}
                >
                  <button
                    className="tertiary action-button"
                    onClick={() => {
                      const emails = selected.map(({ uid, email }) => {
                        const db = firebase.firestore();
                        const users = db
                          .collection(projectName)
                          .doc("users")
                          .collection("user")
                          .doc(String(uid));

                        const allusers = db
                          .collection(projectName)
                          .doc("users")
                          .collection("user")
                          .doc("all");

                        users.delete().catch((error) => {
                          console.error(error);
                        });

                        allusers.update({
                          [String(uid)]: firebase.firestore.FieldValue.delete(),
                        });

                        const removeUserCloudFunction = firebase
                          .functions()
                          .httpsCallable("removeUser");

                        removeUserCloudFunction({
                          uid: uid,
                        });

                        return `${email} deleted`;
                      });

                      setSelected([]);
                      setAlerts(emails);
                      setTimeout(() => {
                        setAlerts([]);
                      }, 5000);
                    }}
                    disabled={false}
                  >
                    <Delete />
                  </button>
                </Tooltip>

                <Tooltip
                  title="View all selected users report(s)"
                  aria-label="View all selected users report(s)"
                >
                  <button
                    className="tertiary action-button"
                    onClick={() => {
                      setViewReport(true);
                    }}
                    disabled={false}
                  >
                    <FindInPage />
                  </button>
                </Tooltip>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            alignItems: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label>All user actions</label>
          <div className="add-user-button">
            <Tooltip
              title="Edit assessment version(s)"
              aria-label="Edit assessment version(s)"
            >
              <button
                className="tertiary action-button"
                onClick={() => history.push(`/${projectName}/cms`)}
                disabled={false}
              >
                <Edit />
              </button>
            </Tooltip>

            <Tooltip title="Add user(s)" aria-label="Add user(s)">
              <button
                className="tertiary action-button"
                onClick={() => history.push(`/${projectName}/add-user`)}
                disabled={false}
              >
                <GroupAdd />
              </button>
            </Tooltip>

            <Tooltip
              title="Download all user(s) raw scores"
              aria-label="Download all user(s) raw scores"
            >
              <button
                className="tertiary action-button"
                onClick={() => {
                  const db = firebase.firestore();
                  const allAssessments = db
                    .collection(projectName)
                    .doc("assessments")
                    .collection(assessmentVersion)
                    .doc("all")
                    .get();

                  allAssessments.then((doc) => {
                    const sectionKeys = Object.keys(doc.data().assessment);

                    const resultsData = [];

                    sectionKeys.forEach((sectionKey) => {
                      Object.keys(doc.data().assessment[sectionKey]).forEach(
                        (subsectionKey) => {
                          Object.keys(
                            doc.data().assessment[sectionKey][subsectionKey]
                          ).forEach((userId) => {
                            const userInfo = users.filter(
                              ({ uid }) => uid === userId
                            );

                            if (userInfo.length > 0) {
                              resultsData.push({
                                Company: userInfo[0].company,
                                Name: userInfo[0].displayName,
                                Email: userInfo[0].email,
                                Section: sectionKey,
                                Subsection: subsectionKey,
                                "Average score": doc.data().assessment[
                                  sectionKey
                                ][subsectionKey][userId],
                              });
                            }
                          });
                        }
                      );
                    });

                    const sortedByName = resultsData.sort((a, b) => {
                      if (a["Name"].toLowerCase() < b["Name"].toLowerCase())
                        //sort string ascending
                        return -1;
                      if (a["Name"].toLowerCase() > b["Name"].toLowerCase())
                        return 1;
                      return 0; //default return value (no sorting)
                    });

                    const options = {
                      fieldSeparator: ",",
                      quoteStrings: '"',
                      decimalSeparator: ".",
                      showLabels: true,
                      showTitle: true,
                      title:
                        "All selected user(s) last completed assessment raw data",
                      useTextFile: false,
                      useBom: true,
                      useKeysAsHeaders: true,
                    };

                    const csvExporter = new ExportToCsv(options);

                    csvExporter.generateCsv(sortedByName);
                  });
                }}
                disabled={false}
              >
                <GetApp />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <UsersTable
        classes={classes}
        users={users}
        history={history}
        setSelected={setSelected}
        selected={selected}
        companyFilter={companyFilter}
        search={search}
        projectName={projectName}
      />

      {alerts && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <div>
            {alerts.length > 0 &&
              alerts.map((alert, index) => (
                <SnackbarContent
                  message={alert}
                  key={`snack${index}`}
                  style={{ marginBottom: "1rem" }}
                />
              ))}
          </div>
        </Snackbar>
      )}
    </div>
  );
};

export default memo(UsersContainer);
