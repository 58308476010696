import React, { memo } from "react";
import logo from "../../assets/img/logo-black.svg";
import { Link } from "react-router-dom";

const FooterV2 = ({ projectName, hideLinks }) => {
  return (
    <footer className="footerv2">
      <div className="footerv2-body">
        <div className="nav-item">
          <img
            src={logo}
            alt="Leadership Equality Index"
            className="project-logo"
          />
        </div>
        <div className="nav-item">
          {!hideLinks && (
            <>
              <p
                className="small"
                style={{ display: "flex", padding: "0.25rem" }}
              >
                <Link to={`/${projectName}/privacy-policy-long`}>
                  Privacy Policy
                </Link>
              </p>{" "}
              <hr />
              <p
                className="small"
                style={{ display: "flex", padding: "0.25rem" }}
              >
                <Link to={`/${projectName}/cookie-policy`}>Cookie Policy</Link>
              </p>{" "}
              <hr />
              <p
                className="small"
                style={{ display: "flex", padding: "0.25rem" }}
              >
                <Link to={`/${projectName}/faq`}>FAQ</Link>
              </p>
            </>
          )}
        </div>
        <div className="nav-item">
          <p className="small">© Copyright All Rights Reserved </p>
        </div>
      </div>
    </footer>
  );
};

export default memo(FooterV2);
