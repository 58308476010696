import React, { memo } from "react";
import PropTypes from "prop-types";
import logo from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";

const Header = ({ email, logout, user }) => {
  return (
    <div className="header">
      <div className="header-body">
        <div className="nav-item">
          <Link to="/leadershipEqualityIndex/landing">
            <img
              src={logo}
              alt="Leadership Equality Index"
              className="project-logo"
            />
          </Link>
        </div>
        <div className="nav-item">
          {email && <p className="email small">{email}</p>}
        </div>
        <div className="nav-item">
          <button className="logout" onClick={logout}>
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  email: PropTypes.string,
  logout: PropTypes.func,
};

Header.defaultProps = {
  email: "",
  logout: null,
};

export default memo(Header);
