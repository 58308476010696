import firebase from "firebase/app";
import "firebase/database"; // If using Firebase database
// import "firebase/storage"; // If using Firebase storage
import "firebase/auth"; // If using auth
import "firebase/functions"; // If using functions
import "firebase/firestore"; // If using firestore

// PROD CONFIG
const firebaseConfig = {
  apiKey: "AIzaSyBrE67p5tXigf7HdGoOnpB78RLQJLER5G4",
  authDomain: "equality-forward-cf035.firebaseapp.com",
  databaseURL: "https://equality-forward-cf035.firebaseio.com",
  projectId: "equality-forward-cf035",
  storageBucket: "equality-forward-cf035.appspot.com",
  messagingSenderId: "356399913763",
  appId: "1:356399913763:web:cee8f5e4d5b523a55b0a96",
  measurementId: "G-FFGBJRZ7XB",
};

// TEST CONFIG
// const firebaseConfig = {
//   apiKey: "AIzaSyDurWRzoneC2Dr3-Gy3ZnD8MKO-mc36H9o",
//   authDomain: "equality-forward-sandbox.firebaseapp.com",
//   databaseURL: "https://equality-forward-sandbox.firebaseio.com",
//   projectId: "equality-forward-sandbox",
//   storageBucket: "equality-forward-sandbox.appspot.com",
//   messagingSenderId: "929659766233",
//   appId: "1:929659766233:web:816e70923486ab544545c8",
//   measurementId: "G-JDBBNTK3HK",
// };

firebase.initializeApp(firebaseConfig).auth();

export default firebase;
