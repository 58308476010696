import React, { useEffect, useState, useCallback } from "react";
import { Questions, ProgressBar, Footer, Feedback } from "../../components";
import firebase from "../../firebase";
import _ from "lodash";
import { currentSection } from "../../utils";
// import welcomeIcon from "../../assets/icons/welcome.svg";
import { ThumbUpOutlined } from "@material-ui/icons";

const Onboarding = (props) => {
  const { history, match, user } = props;
  const [onboardingQuestions, setOnboardingQuestions] = useState([]);
  const [onboardingAnswers, setOnboardingAnswers] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [liveVersion, setLiveVersion] = useState(null);

  const changeAnswers = (data, index, page) => {
    let items;
    items = [...onboardingAnswers];
    let item = { ...items[index] };
    item = data;
    items[index] = item;
    setOnboardingAnswers(items);
  };

  const onExit = useCallback((event) => {
    event.preventDefault();

    return (event.returnValue = "Are you sure you want to close?");
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", onExit);

    return () => {
      window.removeEventListener("beforeunload", (ev) => onExit);
    };
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (claims.isAdmin) {
              history.push(`/${projectName}/users`);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  const { projectName } = match.params;

  useEffect(() => {
    const db = firebase.firestore();
    const liveAssessmentVersion = db
      .collection(projectName)
      .doc("cms")
      .collection("published")
      .doc("live");

    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (liveVersion) {
      const db = firebase.firestore();
      const currentOnboarding = db
        .collection(projectName)
        .doc("cms")
        .collection("version")
        .doc(liveVersion)
        .collection("onboarding")
        .doc("questions")
        .get();

      currentOnboarding.then((doc) => {
        const onboardingQuestionsFirebase = doc.data().questions;
        const emptyAnswersArray = [];

        const shouldInitialiseAnswers =
          emptyAnswersArray.length === onboardingQuestionsFirebase.length &&
          onboardingAnswers.length !== onboardingQuestionsFirebase.length;

        if (!_.isEqual(onboardingQuestionsFirebase, onboardingQuestions)) {
          setOnboardingQuestions(onboardingQuestionsFirebase);
          onboardingQuestionsFirebase.map(() =>
            emptyAnswersArray.push({
              value: "",
              category: "",
            })
          );
        }

        if (shouldInitialiseAnswers) {
          setOnboardingAnswers(emptyAnswersArray);
        }
      });
    }
  }, [onboardingQuestions, onboardingAnswers, liveVersion]);

  const nextOnboardingQuestion = () => {
    if (activeQuestion + 1 === onboardingQuestions.length) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then(() => {
          const db = firebase.firestore();
          const onboardings = db
            .collection(projectName)
            .doc("onboardings")
            .collection(liveVersion)
            .doc(user.uid);

          const allOnboardings = db
            .collection(projectName)
            .doc("onboardings")
            .collection(liveVersion)
            .doc("all");

          onboardings.set(
            {
              onboarding: onboardingAnswers,
            },
            { merge: true }
          );

          allOnboardings.set(
            {
              [String(user.uid)]: {
                onboarding: onboardingAnswers,
              },
            },
            { merge: true }
          );
        })
        .catch((err) => {
          console.error(err);
        });

      const acceptedPrivacyPolicyCloudFunction = firebase
        .functions()
        .httpsCallable("acceptedPrivacyPolicy");

      acceptedPrivacyPolicyCloudFunction({
        uid: user.uid,
      });

      setActiveQuestion(0);
      history.push(`/${projectName}/assessment-intro`);
    } else {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const previousOnboardingQuestion = () => {
    setActiveQuestion(activeQuestion - 1);
  };

  const closeFeedbackMessage = () => {
    setShowWelcomeMessage(false);
  };

  return (
    <>
      <div className="onboarding">
        {activeQuestion === 0 && showWelcomeMessage ? (
          <Feedback
            closeFeedbackMessage={closeFeedbackMessage}
            buttonText="Continue"
          >
            {/* <ThumbUpOutlined style={{ fontSize: "2rem" }} /> */}
            <h2>Great! It is good to have you with us.</h2>
            <p>
              First, we need you to complete a few questions about yourself and
              your job. You only need to do them once. We will store them for
              the next time you visit. Once completed you will be ready to take
              the assessment.
            </p>
          </Feedback>
        ) : (
          <>
            {onboardingQuestions.length > 0 && (
              <div>
                <ProgressBar
                  activeQuestion={activeQuestion + 1}
                  questions={onboardingQuestions}
                  sectionCompleted={false}
                  sectionCompletedVal={false}
                  currentSection={currentSection(
                    activeQuestion,
                    onboardingQuestions
                  )}
                  sectionsRequired={2}
                />

                <>
                  {onboardingQuestions.map(
                    (question, index) =>
                      index === activeQuestion && (
                        <Questions
                          options={question.options}
                          helperText={
                            "None of the following questions will be used to identify you. All of your answers will be kept strictly confidential and will only be used in aggregate."
                          }
                          body={question.body}
                          key={`onboarding-${index}`}
                          setAnswer={(onboardingObject) => {
                            changeAnswers(
                              onboardingObject,
                              index,
                              "onboarding"
                            );
                          }}
                          answer={
                            onboardingAnswers[index] &&
                            onboardingAnswers[index].value
                              ? onboardingAnswers[index].value
                              : ""
                          }
                        />
                      )
                  )}
                </>
              </div>
            )}
          </>
        )}
      </div>

      {!showWelcomeMessage && (
        <Footer
          answers={onboardingAnswers}
          activeQuestion={activeQuestion}
          nextQuestion={nextOnboardingQuestion}
          previousQuestion={previousOnboardingQuestion}
          isAssessment={false}
          numberOfQuestions={onboardingQuestions.length}
        />
      )}
    </>
  );
};

export default Onboarding;
