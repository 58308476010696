export const columnOne = [
  {
    title: "What is the purpose of the Leadership Equality Index (LEI)?",
    body: `In today’s ever-changing work environment, we need leaders who can create cultures that value difference and harness diversity to innovate, create and advance the organisation's aims. Organisations that are dedicated to creating this environment invest in developing their leaders. One of these development tools is the Leadership Equality Index (LEI). The LEI is a self- assessment diagnostic tool that measures what leaders are really doing to create a culture of equality in their organisation. It provides a deep dive assessment into the three key foundational behaviours of an inclusive leader, these include awareness, understanding and practices that advance equality.`,
  },
  {
    title: "How will the Leadership Equality Index (LEI) benefit me?",
    body: `The LEI is really the starting point for making leaders aware of how they are not building cultures of equality and what they need to do differently. Following the assessment leaders are provided feedback on their results and encouraged to identify opportunities to develop. As leaders commit to this development program and begin to put equality into practice, the aim is to support them through this process with ongoing inclusion coaching, to ensure that they have the support needed to reflect on their development needs and continuously improve their inclusive leadership.`,
  },
  {
    title: "Who is the Leadership Equality Index (LEI) assessment for?",
    body: "The LEI can be taken by anyone who manages a direct report.",
  },
  {
    title:
      "How can I best prepare for taking the Leadership Equality Index (LEI)?",
    body: `The LEI is a self-assessment diagnostic tool, which measures what leaders are really doing to create a culture of equality in their organisation. It provides a deep dive assessment into the three key foundational behaviours of an inclusive leader, these include:
      Awareness: Do leaders understand how inequality works in organisations, and why it is a leader’s imperative to tackle this? 
      Understanding: Are leaders aware of their values, and how these values create their teams’ culture? 
      Practice: Do leaders know how to practice inclusion at work? In doing so invite employees to do the same.`,
  },
  {
    title: "Why is this specific assessment being used?",
    body:
      "Currently most leadership inclusion assessment tools, measure general leadership competencies like collaboration or curiosity. These measures do not provide an assessment of the actions leaders are taking to build cultures of equality within their teams. The LEI is the go-to diagnostic tool to assess, evaluate and continuously monitor what leaders are doing to build cultures of equality within their teams and organisations. The tool is based on over 10 years of academic research, and experience developing leaders and building cultures of equality in organisations. The assessment is a data driven, in-depth measurement tool with automated reporting, to provide all people managers with a clear roadmap of what they need to do to advance equality within their teams.",
  },
  {
    title: "What information should I receive beforehand from my company?",
    body: `The organisation requiring you to complete the LEI should have provided you with any available practice materials and all the practical information that it is possible to give. This includes the reasons why you are being asked to take the LEI, how they will be administered, the date, time and location of the LEI session, how long the LEI will last, and details of how to prepare for the LEI. This information might be provided by email beforehand. As the LEI is administered unsupervised and online, please check that you do have the capability to do it:
      Do you have access to a computer with an Internet connection?Will you be able to work on it in a quiet setting, free from distractions for the necessary period of time?
      If you have any worries about completing the LEI over the internet, let the LEI provider know as soon as possible so that alternative arrangements can be made.`,
  },
];

export const columnTwo = [
  {
    title: "How are people with disabilities treated?",
    body:
      "If you or your dependant has a disability and feel that you may need special conditions, it is important that this is brought to the attention of the person responsible for the LEI as soon as the LEI session has been arranged. This will give maximum time for the assessor to check what special requirements can be provided and what arrangements can be made.",
  },
  {
    title: "When will the results of the LEI be given to me?",
    body:
      "The information about giving the LEI results should be given to you when you complete the assessment along with a report summarizing your results. This report is generated using LEI online assessment system. It includes information from the LEI survey. The use of this questionnaire is limited to those people who have received specialist training in its use and interpretation. The report herein is generated from the results of a questionnaire answered by the respondent(s) and substantially reflects the answers made by them. Due consideration must be given to the subjective nature of questionnaire-based ratings in the interpretation of this data. This report has been generated electronically – the user of the software can make amendments and additions to the text of the report.",
  },
  {
    title: "How will I understand what the results mean?",
    body:
      "You will be provided with a summary report after completing the LEI. This will be used by the LEI team to provide you with verbal feedback. The report is merely a summary of the findings",
  },
  {
    title: "How are my results scored?",
    body: `"Scores (e.g. 8 out of 10) obtained on the LEI are typically converted into a ‘standard’ form to facilitate their interpretation. This may be carried out by using tables of ‘norms’ or by reference to criterion scores. Norms provide information about the distribution of scores in some population (for example, ‘Managers in the United States’) and scores can be converted into numbers that show how a person has performed relative to this population.
    Instead of saying the person got 8 out of 10, we might say they performed at a level equivalent to the top 30 percent of the Managers in the United States population. Norms are important because the latter type of statement is more meaningful and useful than the former. The LEI is intended to differentiate between people - to show up differences where these are real. What they should not do is discriminate unfairly. That is, show differences where none exist, or fail to show differences that do exist."`,
  },
  {
    title: "Who can I contact if I have questions?",
    body:
      "For any queries please contact info@leadershipequalityindex or visit www.leadershipequalityindex.com",
  },
];
