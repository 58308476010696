import React from "react";
import { feedbackDetails } from "../../utils";
import _ from "lodash";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Canvas,
} from "@react-pdf/renderer";
import { pdfText } from "./helper";
import fontRegular from "../../assets/fonts/HKGrotesk-Regular.ttf";
import fontLight from "../../assets/fonts/HKGrotesk-Light.ttf";
import fontMedium from "../../assets/fonts/HKGrotesk-Medium.ttf";
import fontBold from "../../assets/fonts/HKGrotesk-Bold.ttf";
import fontExtraBold from "../../assets/fonts/HKGrotesk-ExtraBold.ttf";
import logo from "../../assets/img/logo_color@2x.png";
import frontCoverPdf from "../../assets/img/front-cover-pdf.jpg";
import backCoverPdf from "../../assets/img/back-cover-pdf.jpg";

Font.register({
  family: "HKGrotesk",
  fonts: [
    { src: fontLight, fontWeight: 300 },
    { src: fontRegular, fontWeight: "normal" },
    { src: fontMedium, fontWeight: 500 },
    { src: fontBold, fontWeight: "bold" },
    { src: fontExtraBold, fontWeight: 800 },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: "40pt 40pt 80pt 40pt",
  },
  subsection: {
    display: "flex",
    background: "#ffffff",
    borderRadius: "12pt",
    margin: "0 0 1cm 0",
    flexDirection: "column",
    position: "relative",
    justifyContent: "left",
    alignContent: "left",
    textAlign: "left",
    padding: "2cm",
  },
  text: {
    fontFamily: "HKGrotesk",
    fontWeight: "normal",
    fontSize: "14pt",
    lineHeight: "140%",
    color: "#404040",
  },
  textbody: {
    paddingTop: "15em",
    fontFamily: "HKGrotesk",
    fontWeight: "normal",
    fontSize: "12pt",
    lineHeight: "140%",
    color: "black",
    textAlign: "left",
  },
  textTitle: {
    fontFamily: "HKGrotesk",
    fontWeight: "bold",
    color: "#000",
    fontSize: "20pt",
    lineHeight: "140%",
    textAlign: "center",
    marginBottom: "20em",
  },
  feedbackindicator: {
    width: "1cm",
    height: "1cm",
    borderRadius: "100%",
  },
  resultcontainer: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    margin: "20em 0",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  introcontainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 0 5em 0",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  resultrow: {
    textAlign: "left",
  },
  textheader: {
    fontFamily: "HKGrotesk",
    fontWeight: 800,
    color: "#000",
    fontSize: "14pt",
    lineHeight: "140%",
    margin: "0",
    textAlign: "left",
  },
  textheaderCentered: {
    fontFamily: "HKGrotesk",
    fontWeight: 800,
    color: "#000",
    fontSize: "14pt",
    lineHeight: "140%",
    textAlign: "center",
    margin: "0",
  },
  logo: {
    left: 0,
    top: "-0.3cm",
    position: "fixed",
    width: "3cm",
  },
  copyright: {
    position: "fixed",
    left: 0,
    top: "26cm",
  },
  copyrightText: {
    fontFamily: "HKGrotesk",
    fontWeight: "normal",
    fontSize: "11pt",
    color: "#6e758f",
    lineHeight: "140%",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "1.5rem 0",
  },
  textBlock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    fontWeight: "heavy",
  },
  percent: {
    fontFamily: "HKGrotesk",
    fontWeight: "bold",
    fontSize: "24pt",
  },
  symbol: {
    fontFamily: "HKGrotesk",
    fontSize: "10pt",
    padding: "0 0 5em 0",
    fontWeight: "light",
    color: "#6e758f",
  },
  title: {
    fontFamily: "HKGrotesk",
    fontSize: "10pt",
    fontWeight: "light",
    color: "#6e758f",
  },
  canvas: {
    width: 200,
    height: 200,
  },
  textContent: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "50em",
    left: "50em",
    zIndex: 10000,
    justifyContent: "center",
    alignItems: "center",
    width: 100,
    height: 100,
  },
  textBodyWrapper: {
    textAlign: "justify",
  },
  summarySectionWrp: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    padding: "20em 0 0 0",
  },
  userInfoTable: {
    borderBottomWidth: 1,
    borderColor: "#C4C4C4",
    margin: "20em 0",
  },
  userInfoTableLine: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "5em",
  },
  userInfoTitle: {
    fontFamily: "HKGrotesk",
    fontSize: "8pt",
    fontWeight: 500,
    width: "33.3%",
    textAlign: "left",
    color: "#6E758F",
    lineHeight: "140%",
  },
  userInfoItem: {
    width: "33.3%",
    textAlign: "left",
    fontFamily: "HKGrotesk",
    fontSize: "9pt",
    fontWeight: "normal",
    color: "#000",
    lineHeight: "140%",
  },
  coverPage: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
    orientation: "portrait",
  },
  coverView: {
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  coverBackgroundImage: {
    width: "100%",
    height: "100%",
  },
});

const PDFdocument = ({ data, feedback, sectionsSummary }) => {
  const grouped = _.groupBy(data.length > 0 && data, ({ Section }) => {
    return Section;
  });

  const groupedBySubsection = _.groupBy(
    feedback.length > 0 && feedback,
    ({ subSection }) => {
      return subSection;
    }
  );

  const groupedByEmail = _.groupBy(data.length > 0 && data, ({ Email }) => {
    return Email;
  });

  const userInfo = Object.keys(groupedByEmail).map(
    (email) => groupedByEmail[email][0]
  );

  const drawProgressBar = (painter, progress, feedbackColor, i) => {
    const radius = 60;
    const centerCoordinates = {
      x: 100,
      y: 100,
    };
    const circumference = 2 * Math.PI * radius;
    const progressOffset = ((100 - progress) / 100) * circumference;
    const delta = progress < 10 ? 10 : 18;
    const fi = progress * 3.6 - delta;
    const x =
      progress === 100
        ? centerCoordinates.x
        : centerCoordinates.x + radius * Math.cos(fi / (180 / 3.14));
    const y =
      progress === 100
        ? centerCoordinates.y + radius
        : centerCoordinates.y + radius * Math.sin(fi / (180 / 3.14));

    let dash = 360 - progressOffset;

    if (dash < 10) {
      dash = 10;
    }

    const fillAndStrokeColor = progress === 100 ? feedbackColor : "#EDEDED";

    painter
      .circle(centerCoordinates.x, centerCoordinates.y, radius)
      .lineWidth(14)
      .fillOpacity(0)
      .fillAndStroke(fillAndStrokeColor, fillAndStrokeColor);

    painter
      .circle(centerCoordinates.x, centerCoordinates.y, radius)
      .lineWidth(14)
      .moveTo(100, 160)
      .dash(dash, { space: 360 + dash })
      .lineCap("round")
      .fillOpacity(0)
      .rotate(-270, { origin: [centerCoordinates.x, centerCoordinates.y] })
      .fillAndStroke("color", feedbackColor);

    painter
      .circle(x, y, 3.5)
      .dash(360, { space: 360 })
      .lineCap("butt")
      .lineWidth(1)
      .fillOpacity(1)
      .rotate(progress === 100 ? 90 : -180, {
        origin: [centerCoordinates.x, centerCoordinates.y],
      })
      .fillAndStroke("#fff", "#eeeeee");
  };

  const fixedHeaderFooter = () => {
    return (
      <>
        <Image
          style={styles.logo}
          src={logo}
          alt="Leadership Equality Index"
          fixed={true}
        />
        <View style={styles.copyright} fixed={true}>
          <Text style={styles.copyrightText}>
            © Copyright All Rights Reserved
          </Text>
        </View>
      </>
    );
  };

  const userInfoTable = () => {
    return (
      <View style={styles.userInfoTable}>
        <View style={styles.userInfoTableLine}>
          <Text style={styles.userInfoTitle}>Name</Text>
          <Text style={styles.userInfoTitle}>Company</Text>
          <Text style={styles.userInfoTitle}>Email</Text>
        </View>
        {userInfo.map(({ Name, Company, Email }, i) => (
          <View style={styles.userInfoTableLine} key={i}>
            <Text style={styles.userInfoItem}>{Name || "-"}</Text>
            <Text style={styles.userInfoItem}>{Company || "-"}</Text>
            <Text style={styles.userInfoItem}>{Email || "-"}</Text>
          </View>
        ))}
      </View>
    );
  };

  const introSection = (data) => {
    return (
      <View style={{ ...styles.introcontainer, marginBottom: "5em" }}>
        <View style={styles.resultrow}>
          <Text style={styles.textheaderCentered}>{data.title}</Text>
        </View>
        <View style={styles.textBodyWrapper}>
          {data.body.map((text, i) => (
            <Text style={styles.textbody} key={i}>
              {text}
            </Text>
          ))}
        </View>
      </View>
    );
  };

  const sections = Object.keys(grouped).reverse();

  return (
    <Document>
      <Page size="A4" style={styles.coverPage} wrap>
        <View style={styles.view}>
          <Image
            style={styles.coverBackgroundImage}
            src={frontCoverPdf}
            alt="Leadership Equality Index"
            fixed={true}
          />
        </View>
      </Page>
      <Page size="A4" style={styles.page} wrap>
        <View>
          {fixedHeaderFooter()}
          {userInfo && userInfoTable()}
          {introSection(pdfText[0])}
          {introSection(pdfText[1])}
          {[sections.splice(-1), ...sections].map(
            (sectionKey, sectionIndex) => {
              const section = grouped[sectionKey];
              const sectionName =
                typeof sectionKey === "string" ? sectionKey : sectionKey[0];
              const intro = [...sectionsSummary].find(
                (sec) => sec.section.toLowerCase() === sectionName.toLowerCase()
              );
              return (
                <View key={sectionIndex} break>
                  <Text style={styles.textTitle}>{sectionKey}</Text>
                  {introSection({
                    title: `What is ${intro.section.toLowerCase()}?`,
                    body: [intro.body],
                  })}
                  {section.map((Subsection, subsectionIndex) => {
                    const {
                      feedbackColor,
                      feedbackScore,
                      feedback: feedbackText,
                    } = feedbackDetails(Subsection["Percentile rank"]);

                    const subsectionFeedback =
                      groupedBySubsection?.[Subsection.Subsection][0];

                    const feedback =
                      subsectionFeedback?.feedback?.[feedbackScore];
                    return (
                      <View key={subsectionIndex} wrap={false}>
                        <View style={styles.sectionContainer}>
                          <View>
                            <Canvas
                              paint={(painter) => {
                                drawProgressBar(
                                  painter,
                                  Subsection["Percentile rank"],
                                  feedbackColor,
                                  subsectionIndex
                                );
                              }}
                              style={styles.canvas}
                            />
                            <View style={styles.textContent}>
                              <View>
                                <Text style={styles.title}>
                                  Your percentile score
                                </Text>
                              </View>
                              <View>
                                <Text style={styles.percent}>
                                  {Subsection["Percentile rank"]}
                                  <Text style={styles.symbol}>%</Text>
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View style={styles.resultcontainer}>
                            <View style={styles.resultrow}>
                              <Text
                                style={{
                                  color: feedbackColor,
                                  fontFamily: "HKGrotesk",
                                  fontSize: "10pt",
                                  padding: "15em 0 10em 0",
                                  fontWeight: 500,
                                  lineHeight: "140%",
                                }}
                              >
                                {feedbackText}
                              </Text>
                            </View>
                            <View style={styles.resultrow}>
                              <Text style={styles.textheader}>
                                {Subsection.Subsection}
                              </Text>
                            </View>
                            <View style={styles.textBodyWrapper}>
                              <Text style={styles.textbody}>{feedback}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    );
                  })}
                </View>
              );
            }
          )}
        </View>
      </Page>
      <Page size="A4" style={styles.coverPage} wrap>
        <View style={styles.view}>
          <Image
            style={styles.coverBackgroundImage}
            src={backCoverPdf}
            alt="Leadership Equality Index"
            fixed={true}
          />
        </View>
      </Page>
    </Document>
  );
};

export default PDFdocument;
