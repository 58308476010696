import React, { memo, useState, useEffect } from "react";
import firebase from "../../firebase";
import _ from "lodash";

import { KeyboardArrowLeft, Cancel, Add } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";

const CMSAssessmentSections = ({ history, currentquestionEmail, match }) => {
  const [assessmentSections, setAssessmentSections] = useState([]);
  const [assessmentSectionsBody, setAssessmentSectionsBody] = useState([]);
  const [assessmentSubsections, setAssessmentSubsections] = useState([]);
  const [liveVersion, setLiveVersion] = useState(null);
  const [saving, setSaving] = useState(false);
  const [archivedVersion, setArchivedVersion] = useState([]);

  const { projectName, assessmentVersion } = match.params;

  const db = firebase.firestore();
  const cmsSections = db
    .collection(projectName)
    .doc("cms")
    .collection("version")
    .doc(assessmentVersion)
    .collection("assessment")
    .doc("sections");

  const liveAssessmentVersion = db
    .collection(projectName)
    .doc("cms")
    .collection("published")
    .doc("live");

  const archivedAssessmentVersion = db
    .collection(projectName)
    .doc("cms")
    .collection("published")
    .doc("archived");

  const setAssessmentSection = (data, index) => {
    let items;
    items = [...assessmentSections];
    let item = { ...items[index] };
    item = data;
    items[index] = item;
    setAssessmentSections(items);
  };

  const setAssessmentSectionBody = (data, index) => {
    let items;
    items = [...assessmentSectionsBody];
    let item = { ...items[index] };
    item = data;
    items[index] = item;
    setAssessmentSectionsBody(items);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            /**
             * CHECK CURRENT USER IS ADMIN
             */
            const { claims } = idTokenResult;

            if (!claims.isAdmin) {
              history.goBack();
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    });
  }, [history]);

  useEffect(() => {
    const unsubscribe = liveAssessmentVersion.onSnapshot((doc) => {
      if (!_.isEqual(liveVersion, doc.data().version)) {
        setLiveVersion(doc.data().version);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = archivedAssessmentVersion.onSnapshot((doc) => {
      if (doc.data() && !_.isEqual(archivedVersion, doc.data().version)) {
        setArchivedVersion(doc.data().version);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = cmsSections.onSnapshot((doc) => {
      if (doc.data()) {
        const sections = Object.keys(doc.data()).sort();

        if (!_.isEqual(sections, assessmentSections)) {
          setAssessmentSections(sections);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = cmsSections.onSnapshot((doc) => {
      if (doc.data()) {
        const sections = Object.keys(doc.data()).sort();
        const sectionsBody = sections.map((section) => {
          return doc.data()[section].body;
        });

        if (!_.isEqual(sectionsBody, assessmentSectionsBody)) {
          setAssessmentSectionsBody(sectionsBody);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = cmsSections.onSnapshot((doc) => {
      if (doc.data()) {
        const sections = Object.keys(doc.data()).sort();
        const sectionsBody = sections.map((section) => {
          return doc.data()[section].subsections;
        });

        if (!_.isEqual(sectionsBody, assessmentSubsections)) {
          setAssessmentSubsections(sectionsBody);
        }
      }
    });
    return () => unsubscribe();
  }, []);

  const isLiveOrArchived =
    liveVersion === assessmentVersion ||
    archivedVersion.includes(assessmentVersion);

  const updateFirestore = (redirect, section) => {
    setSaving(true);

    let redirectURL = "";

    switch (redirect) {
      case "back":
        redirectURL = `/${projectName}/cms/${assessmentVersion}`;
        break;
      case "forward":
        redirectURL = `/${projectName}/cms/${assessmentVersion}/assessment/sections/${encodeURI(
          section
        )}/subSections`;
        break;

      default:
        break;
    }

    cmsSections
      .delete()
      .then(() => {
        if (assessmentSections.length > 0) {
          console.log("Document successfully deleted!");
          assessmentSections.forEach((section, sectionIndex) => {
            if (section) {
              cmsSections.set(
                {
                  [section]: {
                    body: assessmentSectionsBody[sectionIndex]
                      ? assessmentSectionsBody[sectionIndex]
                      : "",
                    subsections: assessmentSubsections[sectionIndex]
                      ? assessmentSubsections[sectionIndex]
                      : {},
                  },
                },
                { merge: true }
              );
            }

            // if (sectionIndex + 1 >= assessmentSections.length) {
            // }
          });
        } else {
          cmsSections.set({}, { merge: true });
        }

        setTimeout(() => {
          setSaving(false);
          history.push(redirectURL);
        }, 3000);
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  return (
    <>
      <div className="page">
        <div className="toolbar">
          <div className="toolbar-items">
            <button
              className="secondary back"
              onClick={() => {
                updateFirestore("back");
              }}
              disabled={saving}
            >
              {saving ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: "1.25rem",
                      height: "1.25rem",
                      fill: "black",
                      color: "black",
                      marginRight: "0.5rem",
                    }}
                  />
                  Saving
                </div>
              ) : (
                <>
                  <KeyboardArrowLeft />
                  Back and save
                </>
              )}
            </button>
          </div>

          <div className="toolbar-items">
            <h1 className="questions-title">Edit sections</h1>
          </div>

          <div className="toolbar-items"></div>
        </div>

        <div className="cms-assessment-container">
          {saving ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <CircularProgress
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  fill: "black",
                  color: "black",
                  marginRight: "0.5rem",
                }}
              />
            </div>
          ) : (
            <>
              {assessmentSections.map((section, sectionIndex) => {
                return (
                  <div
                    className="cms-assessment-section"
                    key={`section-${sectionIndex}`}
                  >
                    <p className="subtitle">
                      <strong>{section}</strong>
                    </p>
                    <label>Title</label>
                    <input
                      value={section}
                      onChange={(e) => {
                        if (
                          /^[A-Za-z-\s]*$/gi.test(e.target.value) &&
                          !isLiveOrArchived &&
                          e.target.value.length < 100
                        ) {
                          setAssessmentSection(e.target.value, sectionIndex);
                        }
                      }}
                      disabled={isLiveOrArchived || saving}
                    />
                    {section && (
                      <>
                        <label>Body</label>
                        <textarea
                          value={assessmentSectionsBody[sectionIndex]}
                          onChange={(e) => {
                            if (!isLiveOrArchived) {
                              setAssessmentSectionBody(
                                e.target.value,
                                sectionIndex
                              );
                            }
                          }}
                          disabled={isLiveOrArchived || saving}
                        />
                      </>
                    )}

                    {section && (
                      <button
                        className="secondary"
                        onClick={() => {
                          updateFirestore("forward", section);
                        }}
                        disabled={saving}
                      >
                        {saving
                          ? "Saving"
                          : `View ${section && section.toLowerCase()}`}
                      </button>
                    )}

                    {!isLiveOrArchived && (
                      <div className="delete-container">
                        <Cancel
                          onClick={() => {
                            let itemsAssessmentSections;
                            let itemsAssessmentSectionsBody;
                            let itemsAssessmentSubsections;

                            itemsAssessmentSections = [...assessmentSections];
                            itemsAssessmentSectionsBody = [
                              ...assessmentSectionsBody,
                            ];
                            itemsAssessmentSubsections = [
                              ...assessmentSubsections,
                            ];
                            itemsAssessmentSections.splice(sectionIndex, 1);
                            itemsAssessmentSectionsBody.splice(sectionIndex, 1);
                            itemsAssessmentSubsections.splice(sectionIndex, 1);
                            setAssessmentSections(itemsAssessmentSections);
                            setAssessmentSectionsBody(
                              itemsAssessmentSectionsBody
                            );
                            setAssessmentSubsections(
                              itemsAssessmentSubsections
                            );
                          }}
                          disabled={saving}
                        />
                      </div>
                    )}
                  </div>
                );
              })}

              {!isLiveOrArchived && (
                <button
                  className={`add-another-user-container `}
                  onClick={() => {
                    setAssessmentSections([...assessmentSections, ""]);
                    setAssessmentSectionsBody([...assessmentSectionsBody, ""]);
                    setAssessmentSubsections([...assessmentSubsections, {}]);
                  }}
                  disabled={saving}
                >
                  <p className={`add-another-user-content`}>
                    <Add />
                    Add section
                  </p>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(CMSAssessmentSections);
