export const pdfText = [
  {
    title: "Your Report",
    body: [
      "The LEI is a self-assessment diagnostic tool that serves as the starting point for making leaders aware of what they can do differently to create a culture of equality. The questions you answered assess your behaviour, preferences and attitudes in relation to advancing equality at work. Your responses have been compared with a large group of people who have answered in the same questionnaire. When considering this report’s description of your approach to equality at work, it is important to recognise that it is based on the answers you gave and is your own view, representing the way you see your behaviour, rather than how you might be described by another person.",
      "The report can give important clues to understand the way you build cultures of equality at work and it is likely to enable us to predict a good deal about your inclusive leadership style. Leaders need to disrupt their denial about difference, and different experiences of working life, by creating awareness of how inequality reveals itself in their organisation.​ To solve inequality, leaders need to understand how it is experienced in their team, department and organisation.​ The goal is to make equality the way leaders lead, employees behave and workplaces work.​ This report details the information under the three domains of awareness, understanding and practice. You can see your results summarised below with recommendations for your development.",
    ],
  },
  {
    title: "Confidentiality",
    body: [
      "Equality Forward is committed to the protection of any personal data we obtain from you through our website or through any other means. Our service involves the production of an automated report based on your assessment submissions. We use all information we collect so that we can provide our online assessment tool, provide you and our employer clients with automated reports, provide information about our service and answer enquiries about our business. For more information see our Privacy Policy.",
    ],
  },
];
