import React, { useEffect } from "react";

import logo from "../../assets/img/logo-colour.svg";
import { Link } from "react-router-dom";

import firebase from "../../firebase";

const Forbidden = () => {
  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  });

  return (
    <>
      <div className="page-not-found">
        <div className="container">
          <div>
            <img
              src={logo}
              alt="Leadership Equality Index"
              className="project-logo"
            />
          </div>
          <div className="content">
            <h1>Access denied</h1>
            <p className="selector">
              You do not have the required permissions. Please contact the
              administrator for access to the assessment
            </p>
          </div>
          <div>
            <p>
              Find out more about this service at our website{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.equalityforward.com"
              >
                https://www.equalityforward.com
              </a>
            </p>

            <p>
              Terms of service:{" "}
              <Link to="privacy-policy-long">Privacy Policy</Link> |{" "}
              <Link to="cookie-policy">Cookie Policy</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forbidden;
