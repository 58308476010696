import React, { memo } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRoute = (props) => {
  const { component: Component, user, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.uid ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/leadershipEqualityIndex/landing",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.func.isRequired,
  ]),
};

export default memo(PrivateRoute);
